import './main.css'
import gsap from 'gsap'
import * as THREE from 'three'
// import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
// import { FlyControls } from 'three/examples/jsm/controls/FlyControls'
import { GUI } from 'dat.gui'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { InteractionManager } from 'three.interactive'

const world = {
    sizes: {
        width: window.innerWidth,
        height: Math.min(window.innerHeight / 1.75, window.innerWidth / 2)
    },
    camera: {
        cameraX: 9.5,
        cameraY: 3,
        cameraZ: 15.5,
        cameraRotX: -10,
        cameraRotY: -0.5,
        cameraRotZ: -8.5
    },
    directFront: {
        directFrontX: 5.1,
        directFrontY: 4.5,
        directFrontZ: 3.4
    },
    directBack: {
        directBackX: -9.6,
        directBackY: 1.7,
        directBackZ: -1,
        directBackTargetY: 1,
        directBackTargetZ: 0
    },
    doordata: {
        currentdoor: 34,
        startdoor: 32,
        enddoor: 36
    }
}

// ---------- Canvas, Scene and Camera ----------

const canvas = document.querySelector('canvas.webgl')

const scene = new THREE.Scene()
scene.background = new THREE.Color(0x999999);

const camera = new THREE.PerspectiveCamera(10, world.sizes.width / world.sizes.height, 0.1, 2000)
updateCamera()

console.log("world.sizes.width: " + world.sizes.width)
console.log("world.sizes.height: " + world.sizes.height)

function updateCamera() {
    camera.position.set( world.camera.cameraX, world.camera.cameraY, world.camera.cameraZ );
    camera.lookAt( world.camera.cameraRotX, world.camera.cameraRotY, world.camera.cameraRotZ );
}

const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    antialias: true
})

renderer.setSize(world.sizes.width, world.sizes.height)
renderer.setPixelRatio(devicePixelRatio)
renderer.shadowMap.enabled = true
renderer.shadowMap.type = THREE.PCFSoftShadowMap // options are THREE.BasicShadowMap | THREE.PCFShadowMap | THREE.PCFSoftShadowMap

const interactionManager = new InteractionManager(
    renderer,
    camera,
    renderer.domElement
);

// // ---------- GUI Controls ----------

// const gui = new GUI()

// gui.add(world.camera, 'cameraX', -20, 20, 0.1).onChange(updateCamera)
// gui.add(world.camera, 'cameraY', -20, 20, 0.1).onChange(updateCamera)
// gui.add(world.camera, 'cameraZ', -20, 20, 0.1).onChange(updateCamera)
// gui.add(world.camera, 'cameraRotX', -15, 15, 0.1).onChange(updateCamera)
// gui.add(world.camera, 'cameraRotY', -15, 15, 0.1).onChange(updateCamera)
// gui.add(world.camera, 'cameraRotZ', -15, 15, 0.1).onChange(updateCamera)

// gui.add(world.directFront, 'directFrontX', -20, 20, 0.1).onChange(updateDirectFront)
// gui.add(world.directFront, 'directFrontY', -20, 20, 0.1).onChange(updateDirectFront)
// gui.add(world.directFront, 'directFrontZ', -20, 20, 0.1).onChange(updateDirectFront)

// gui.add(world.directBack, 'directBackX', -50, 50, 0.1).onChange(updateDirectBack)
// gui.add(world.directBack, 'directBackY', -50, 50, 0.1).onChange(updateDirectBack)
// gui.add(world.directBack, 'directBackZ', -50, 50, 0.1).onChange(updateDirectBack)
// gui.add(world.directBack, 'directBackTargetY', -50, 50, 0.1).onChange(updateDirectBack)
// gui.add(world.directBack, 'directBackTargetZ', -50, 50, 0.1).onChange(updateDirectBack)

// const controls = new OrbitControls(camera, canvas)
// const controls = new FlyControls( camera, canvas )

// ---------- Materials ----------

const corridorMaterial = new THREE.MeshPhongMaterial({
    color: 0x0b5f6e
})

const doornumMaterial = new THREE.MeshPhongMaterial({
    color: 0xffffff,
    shininess: 0
})

const arrowMaterial = new THREE.MeshPhongMaterial({
    color: 0xffffff,
})

const doorMaterial = new THREE.MeshPhongMaterial({
    color: 0x9b6d54,
    shininess: 0
})

const doorHoverMaterial = new THREE.MeshPhongMaterial({
    color: 0x9b6d54,
    shininess: 0
});

// ---------- Models and Meshes ----------

const loader = new GLTFLoader();

let model

loader.load( 'models/wall-with-more-doors-240405v2.glb', function ( gltf ) {
        model = gltf.scene;

        model.userData = { opendoor: ''};
        scene.add( model );

        model.traverse(function (child) {

            if (child.isMesh) {
                // console.log(child.id + " - " + child.name);
                child.castShadow = true;
                child.receiveShadow = true;
            }

            if (child.name === "Text") {
                child.castShadow = true;
                child.material.colorWrite = false;
                child.material.depthWrite = false;
            }

            if ( child.name.startsWith('Door')) { 

                interactionManager.add(child);
                child.material = doorMaterial;
                child.addEventListener('mouseover', doorcolor)
                child.addEventListener('mouseout', doorcolor)
                child.addEventListener('click', opendoorevt)

            }

            if ( child.name.startsWith('frame')) {
                child.material = doorMaterial;
            }

            if ( child.name == 'wall') { 
                child.material = corridorMaterial;
            }

            if (['Num','Handle'].some(word => child.name.startsWith(word))) {
                child.material = doornumMaterial;
            }

        });

    },
    // called while loading is progressing
    function ( xhr ) {

        console.log( ( xhr.loaded / xhr.total * 100 ) + '% loaded' );

    },
    // called when loading has errors
    function ( error ) {

        console.log( 'An error happened' );

    }
);

// Create arrows

const arrowleft = new THREE.Shape([
    new THREE.Vector2(0, 0),
    new THREE.Vector2(0.2, 0.3),
    new THREE.Vector2(0.3, 0.3),
    new THREE.Vector2(0.1, 0),
    new THREE.Vector2(0.3, -0.3),
    new THREE.Vector2(0.2, -0.3),
])

const arrowgeometry = new THREE.ExtrudeGeometry( arrowleft, {
    steps: 1,
    depth: 0.03,
    bevelEnabled: false,
});

// const arrowleftmesh = new THREE.Mesh( arrowgeometry.scale(0.8,0.8,0.8), arrowMaterial );
const arrowleftmesh = new THREE.Mesh( arrowgeometry.scale(1,1,1), arrowMaterial );
arrowleftmesh.name = "arrowleftmesh"

const arrowrightmesh = arrowleftmesh.clone()
arrowrightmesh.name = "arrowrightmesh"

arrowleftmesh.position.set(-2.5,1,0.05)
arrowrightmesh.position.set(0,1,0.05)

arrowrightmesh.rotateZ(THREE.MathUtils.degToRad(-180))

interactionManager.add(arrowleftmesh);
interactionManager.add(arrowrightmesh);

arrowleftmesh.addEventListener('mouseover', arrowcolor)
arrowleftmesh.addEventListener('mouseout', arrowcolor)

arrowrightmesh.addEventListener('mouseover', arrowcolor)
arrowrightmesh.addEventListener('mouseout', arrowcolor)

const arrowboxgeometry = new THREE.PlaneGeometry( 1.2, 1.2 )
const arrowboxmaterial = new THREE.MeshPhongMaterial({
    color: 0xff0000,
})
arrowboxmaterial.opacity = 0;
arrowboxmaterial.transparent = true;
const arrowleftboxmesh = new THREE.Mesh( arrowboxgeometry.scale(1.2,1.2,1.2), arrowboxmaterial );
arrowleftboxmesh.name = "arrowleftboxmesh"

const arrowrightboxmesh = arrowleftboxmesh.clone()
arrowrightboxmesh.name = "arrowrightboxmesh"

arrowleftboxmesh.position.set(-2.5,1,0.05)
arrowrightboxmesh.position.set(0,1,0.05)

arrowrightboxmesh.rotateZ(THREE.MathUtils.degToRad(-180))

interactionManager.add(arrowleftboxmesh);
interactionManager.add(arrowrightboxmesh);

arrowleftboxmesh.addEventListener('mouseover', arrowcolor)
arrowleftboxmesh.addEventListener('mouseout', arrowcolor)

arrowrightboxmesh.addEventListener('mouseover', arrowcolor)
arrowrightboxmesh.addEventListener('mouseout', arrowcolor)

arrowleftboxmesh.addEventListener('click', (e) => arrowaction(e, 'arrowleftmesh'));
arrowrightboxmesh.addEventListener('click', (e) => arrowaction(e, 'arrowrightmesh'));

const arrowgroup = new THREE.Group();
arrowgroup.add( arrowleftmesh );
arrowgroup.add( arrowrightmesh );
arrowgroup.add( arrowleftboxmesh );
arrowgroup.add( arrowrightboxmesh );
scene.add(arrowgroup)

// Create base mesh

const floorGeometry = new THREE.PlaneGeometry(50,10,1,1)
const floorMesh = new THREE.Mesh(floorGeometry,corridorMaterial)
floorMesh.receiveShadow = true
floorMesh.rotateX(THREE.MathUtils.degToRad(-90))
floorMesh.position.z = 4.9
scene.add(floorMesh)

window.addEventListener('resize', () =>
{
    // Update sizes
    world.sizes.width = window.innerWidth
    world.sizes.height = Math.min(window.innerHeight / 1.75, window.innerWidth / 2)

    // Update camera
    camera.aspect = world.sizes.width / world.sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(world.sizes.width, world.sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

// ---------- Lighting ----------

const directFrontlight = new THREE.DirectionalLight(0xffffff, 2)
directFrontlight.position.set( world.directFront.directFrontX, world.directFront.directFrontY, world.directFront.directFrontZ );
scene.add( directFrontlight )

const spotlight = new THREE.SpotLight( 0xffffff, 2);
spotlight.position.set( world.directBack.directBackX, world.directBack.directBackY, world.directBack.directBackZ );
spotlight.castShadow = true;
spotlight.angle = 0.9;

spotlight.shadow.mapSize.width = 2048;
spotlight.shadow.mapSize.height = 2048;

spotlight.target.position.x = world.directBack.directBackX;
spotlight.target.position.y = world.directBack.directBackTargetY;
spotlight.target.position.z = world.directBack.directBackTargetZ;

scene.add( spotlight )
scene.add( spotlight.target );

function updateDirectFront() {
    directFrontlight.position.set( world.directFront.directFrontX, world.directFront.directFrontY, world.directFront.directFrontZ );
}

function updateDirectBack() {
    spotlight.position.set( world.directBack.directBackX, world.directBack.directBackY, world.directBack.directBackZ )
    spotlight.target.position.x = world.directBack.directBackX
    spotlight.target.position.y = world.directBack.directBackTargetY;
    spotlight.target.position.z = world.directBack.directBackTargetZ;
    spotlightcamerahelper.update()
}

const mouse = {
    x: undefined,
    y: undefined
}

const visits = document.getElementsByClassName('visitaction');
for (var i = 0; i < visits.length; i++) {
    visits[i].addEventListener('click', visitsite, false);
}

const showmore = document.getElementsByClassName('moreaction');
for (var i = 0; i < showmore.length; i++) {
    showmore[i].addEventListener('click', showdetails, false);
}

addEventListener('mousemove', (event) => {
    mouse.x = (event.clientX / world.sizes.width) * 2 - 1
    mouse.y = -(event.clientY / world.sizes.height) * 2 + 1
})

// controls.addEventListener( "change", event => {  
//     console.log( '---' )
//     console.log( 'camera.position.x = ' + controls.object.position.x )
//     console.log( 'camera.position.y = ' + controls.object.position.y )
//     console.log( 'camera.position.z = ' + controls.object.position.z )
//     console.log( 'camera.rotation.x = ' + controls.object.rotation.x )
//     console.log( 'camera.rotation.y = ' + controls.object.rotation.y )
//     console.log( 'camera.rotation.z = ' + controls.object.rotation.z )
// })

const arrowmaterialcolor = new THREE.Color( arrowMaterial.color.getHex() )

function arrowcolor(evt) {

    if (evt.type == 'mouseover') {
        document.body.style.cursor = 'pointer'
        arrowmaterialcolor.set(0xdcba97)

    } else if (evt.type == 'mouseout') {
        document.body.style.cursor = 'default'
        arrowmaterialcolor.set(0xffffff)
    }

}

// function arrowaction(evt) {
function arrowaction(evt, arrowname) {

    console.log('arrowname: ' + arrowname)
    console.log('world.doordata.currentdoor: ' + world.doordata.currentdoor)

    if (arrowname == 'arrowleftmesh' && (world.doordata.currentdoor > world.doordata.startdoor)) {
        
        opendoor(parseInt(world.doordata.currentdoor) - 1)
        activatecard( document.querySelector('[data-doorid="' + parseInt(world.doordata.currentdoor) + '"]') ) 
        scrolltocard( document.querySelector('.info-card-column') )

    } else if (arrowname == 'arrowrightmesh' && (world.doordata.currentdoor < world.doordata.enddoor)) {
        
        opendoor(parseInt(world.doordata.currentdoor) + 1)
        activatecard( document.querySelector('[data-doorid="' + parseInt(world.doordata.currentdoor) + '"]') )
        scrolltocard( document.querySelector('.info-card-column') )

    } else {
        return
    }

}

let doorid, doormesh

const doorhovermaterialcolor = new THREE.Color( doorHoverMaterial.color.getHex() )

function doorcolor(evt) {
    if (evt.target.id != '') {
        doorid = evt.target.id
    } else {
        return
    }

    doormesh = model.getObjectById(parseInt(doorid))

    if (evt.type == 'mouseover') {

        //reset all door materials
        model.traverse(function (child) {
            if ( child.name.startsWith('Door') ) {
                child.material = doorMaterial
            }
        })

        document.body.style.cursor = 'pointer'
        doormesh.material = doorHoverMaterial
        doorhovermaterialcolor.set(0xbb8d74)

    } else if (evt.type == 'mouseout') {
        document.body.style.cursor = 'default'
        doorhovermaterialcolor.set(0x9b6d54)
    }

}

function closedoor(doorid) {
    gsap.to( model.getObjectById(parseInt(doorid)).rotation, {
        duration: 1,
        z: 0,
        ease: 'sine.inOut'
    });
    model.userData.opendoor = ''
}

function opendoorevt(evt) {

    console.log('opendoorevt evt.target: ' + evt.target)
    console.log('opendoorevt evt.target.id: ' + evt.target.id)

    if (evt.target.id != '') {
        doorid = evt.target.id
        activatecard( document.querySelector('[data-doorid="' + doorid + '"]') )
        scrolltocard( document.querySelector('.info-card-column') )
    } else {
        doorid = this.getAttribute("data-doorid")
        activatecard( this )
    }

    if (doorid) {
        opendoor(doorid)
    } else {
        return
    }

}

function opendoor(doorid) {

    let opendoorid = model.userData.opendoor
    // console.log('opendood(doorid): ' + doorid)
    // console.log('model.userData.opendoor: ' + opendoorid)

    if (opendoorid == doorid) {
        closedoor(opendoorid)
        return
    }

    doormesh = model.getObjectById(parseInt(doorid));

    let ang = THREE.MathUtils.degToRad(-110)

    var tl = gsap.timeline();

    tl.to( camera.position, {
        x: world.camera.cameraX + doormesh.position.x + 0.85,
        duration: 1.5,
        ease: 'sine.inOut',
    })

    tl.to( arrowgroup.position, {
        x: doormesh.position.x + 0.85,
        duration: 1.5,
        ease: 'sine.inOut',
    }, "<")

    if (opendoorid != '') {
        closedoor(opendoorid)
    }

    tl.to( spotlight.position, {
		duration: 0,
		x: doormesh.position.x - 0.5,
	}, "1");

    tl.to( spotlight.target.position, {
		duration: 0,
		x: doormesh.position.x - 0.5,
	}, "1");

	tl.to( doormesh.rotation, {
		duration: 1.5,
		z: ang,
        ease: 'sine.inOut'
	}, ">");

    model.userData.opendoor = doorid
    world.doordata.currentdoor = doorid

}

function activatecard(cardel) {

    const activecard = cardel
    const allcards = document.getElementsByClassName('card')
    for (var i = 0; i < allcards.length; i++) {
        if (allcards[i] != activecard) {
            allcards[i].classList.remove('card-active')
        }
    }
    closedetails();
    activecard.classList.toggle('card-active')
}

function closedetails(activecard) {
    const allcards = document.getElementsByClassName('card');
    for (var i = 0; i < allcards.length; i++) {
        if (allcards[i] != activecard) {
            allcards[i].classList.remove('expanded')
        }
    }
}

function showdetails(evt) {
    evt.stopImmediatePropagation()

    const activecard = this.parentElement.parentElement

    closedetails(activecard);

    activecard.classList.toggle('expanded')
}

function scrolltocard(cardel) {

    //info-card-column

    // const cardel = document.getElementsByClassName('info-card-column')
    
    const offset = world.sizes.height + 16;
    // console.log('offset: ' + offset)
    const bodyRectTop = document.body.getBoundingClientRect().top;
    // console.log('bodyRectTop: ' + bodyRectTop)
    const canvasRectTop = canvas.getBoundingClientRect().top;
    // console.log('canvasRectTop: ' + canvasRectTop)
    const cardRectTop = cardel.getBoundingClientRect().top;
    // console.log('cardRectTop: ' + cardRectTop)
    const canvasPosition = canvasRectTop - bodyRectTop;
    // console.log('canvasPosition: ' + canvasPosition)
    const cardPosition = cardRectTop - bodyRectTop;
    // console.log('cardPosition: ' + cardPosition)
    const cardOffset = cardPosition - offset;
    // console.log('cardOffset: ' + cardOffset)

    window.scrollTo({
        top: cardOffset,
        behavior: 'smooth'
    });
}

function visitsite() {
    // event.preventDefault();
    event.stopImmediatePropagation();
}

function animate() {
    requestAnimationFrame(animate)

    interactionManager.update()

    arrowMaterial.color.lerp(arrowmaterialcolor, 0.1)
    doorHoverMaterial.color.lerp(doorhovermaterialcolor,0.1)

    renderer.render(scene, camera)
}

animate()